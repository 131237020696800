import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'src/app/store'
import { User } from 'src/interfaces/InterfaceGlobal'

const initialState = {
  user: null,
  isUserLogged: false,
  updateUser: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setIsUserLogged: (state, action: PayloadAction<boolean>) => {
      state.isUserLogged = action.payload
    },
    setUpdateUser: (state, action: PayloadAction<boolean>) => {
      state.updateUser = action.payload
    },
  },
})

export const { setUser, setIsUserLogged, setUpdateUser } = userSlice.actions

export const selectUser = (state: AppState) => state.user.user

export default userSlice.reducer

import jwtDecode from 'jwt-decode'
import { ParsedUrlQuery } from 'querystring'
import { Project } from 'src/interfaces/InterfaceGlobal'
import newsList from '../static/news/news.json'

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const expiresInSeconds = (userToken: string, seconds: number) => {
  const decoded: any = jwtDecode(userToken)
  const expiration = decoded.exp - Date.now() / 1000
  return expiration < seconds && expiration > 0
}

export const getHigherStock = (projects: Array<Project>) => {
  let higherStock = 0
  projects.forEach((project) => {
    if (project.available_volume_credits > higherStock) {
      higherStock = project.available_volume_credits
    }
  })
  return higherStock
}

// eslint-disable-next-line no-unused-vars
export const getExpensivePrice = (projects: Array<Project>) => {
  let moreExpensive = {
    is_vintage: false,
    final_unit_price: {
      sell_base_amount: 0,
      fee_amount: 0
    },
    vintage_items: []
  }
  // eslint-disable-next-line no-unused-vars
  const expensive = projects.forEach((project) => {
    if (project.is_vintage) {
      if (moreExpensive.is_vintage) {
        if (
          project.vintage_items[0]?.final_unit_price
            ?.sell_base_amount +
                        project.vintage_items[0]?.final_unit_price?.fee_amount >
                    moreExpensive.vintage_items[0].final_unit_price
                      .sell_base_amount +
                        moreExpensive.vintage_items[0].final_unit_price
                          .fee_amount
        ) {
          moreExpensive = project
        }
      } else {
        if (
          project.vintage_items[0]?.final_unit_price
            ?.sell_base_amount +
                        project.vintage_items[0]?.final_unit_price?.fee_amount >
                    moreExpensive.final_unit_price.sell_base_amount +
                        moreExpensive.final_unit_price.fee_amount
        ) {
          moreExpensive = project
        }
      }
    } else {
      if (moreExpensive.is_vintage) {
        if (
          project.final_unit_price.sell_base_amount +
                        project.final_unit_price.fee_amount >
                    moreExpensive.vintage_items[0].final_unit_price
                      .sell_base_amount +
                        moreExpensive.vintage_items[0].final_unit_price
                          .fee_amount
        ) {
          moreExpensive = project
        }
      } else {
        if (
          project.final_unit_price.sell_base_amount +
                        project.final_unit_price.fee_amount >
                    moreExpensive.final_unit_price.sell_base_amount +
                        moreExpensive.final_unit_price.fee_amount
        ) {
          moreExpensive = project
        }
      }
    }
  })
  if (moreExpensive.is_vintage) {
    return {
      project: moreExpensive,
      price:
                moreExpensive.vintage_items[0].final_unit_price
                  .sell_base_amount +
                moreExpensive.vintage_items[0].final_unit_price.fee_amount
    }
  } else {
    return {
      project: moreExpensive,
      price:
                moreExpensive.final_unit_price.sell_base_amount +
                moreExpensive.final_unit_price.fee_amount
    }
  }
}

export const getOlderVintage = (projects: Array<Project>) => {
  let olderVintage = new Date().getFullYear()

  projects?.forEach((project) => {
    if (project.is_vintage) {
      for (let i = 0; i < project.vintage_items.length; i++) {
        if (project.vintage_items[i].year < olderVintage) {
          olderVintage = project.vintage_items[i].year
        }
      }
    }
  })
  return olderVintage
}
export const getNewestVintage = (projects: Array<Project>) => {
  return Math.max(
    ...(projects?.map((p) => p.vintage_items.map((v) => v.year))?.flat() ||
            [])
  )
}
export const validateOnlyNumbers = (input: string): boolean => {
  const onlyNumbersRegExp = /^[0-9,.]+$/
  return onlyNumbersRegExp.test(input)
}

export const shuffle = (array) => {
  return array.toSorted(() => {
    const randomBuffer = new Uint32Array(1);
    crypto.getRandomValues(randomBuffer);
    return randomBuffer[0] % 2 ? 1 : -1;
  });
}
export const inverseMapToScale = (scaledRatio, minBound, maxBound) =>  {
  const range = maxBound - minBound
  return ((scaledRatio - 1) / 99) * range + minBound
}
/*
La función queryFilterWriter se encarga de generar y actualizar la URL
de la página según las opciones seleccionadas en un filtro.

filterName: string: Es el nombre del filtro que se va a aplicar en la consulta.
Es un valor de tipo cadena de texto.
selectedOptions: any[]: Es un arreglo que contiene las opciones seleccionadas
  para el filtro. Puede contener cualquier tipo de valor.
options: any[] | null: Es un arreglo de opciones disponibles para el filtro,
  o puede ser null si no es necesario realizar una comparación, (se ignorara
  el parámetro propertyToCompare) por ej. se puede enviar null cuando en
  selectedOptions ya se encuentre guardado el objeto entero, o cuando en
  la query se desea guardar el mismo valor que se encuentra almacenado en la
  lista selectedOptions.
propertyToCompare: string = 'id': Es el nombre de la propiedad que se utilizará
  para comparar las opciones seleccionadas con las opciones disponibles. Por defecto,
  el valor es 'id'.
propertyToSave: string = 'name': Es el nombre de la propiedad que se guardará
  en la consulta, si el valor es null se tomaran directamente los valores de
  selectedOptions (se ignorara el parámetro options y propertyToCompare). Por defecto, el valor es 'name'.
router: Es el objeto que contiene información sobre la URL actual.
*/
export const queryFilterWriter = (
  filterName: string,
  selectedOptions: any[],
  options: any[] | null,
  propertyToCompare: string = 'id',
  propertyToSave: string | null = 'name',
  router
) => {
  const query: ParsedUrlQuery = getQueryObject()

  if (selectedOptions.length !== 0) {
    let newQuery

    if (propertyToSave === null) {
      newQuery = selectedOptions
    } else {
      newQuery =
                options === null
                  ? selectedOptions.map((op) => op[propertyToSave])
                  : options
                    .filter((op) =>
                      selectedOptions.includes(op[propertyToCompare])
                    )
                    .map((op) => op[propertyToSave])
    }

    const updatedQuery = {
      ...query
    }

    updatedQuery[filterName] = newQuery

    const searchParams = new URLSearchParams()

    // Agregamos múltiples valores al parámetro '[filterName]'
    for (const key of Object.keys(updatedQuery)) {
      const value = updatedQuery[key]
      if (Array.isArray(value)) {
        value.forEach((item) => {
          searchParams.append(key, item)
        })
      } else {
        searchParams.append(key, value)
      }
    }

    // Utilizamos 'replaceState' para modificar la URL sin recargar la página
    window.history.replaceState(
      {
        ...window.history.state,
        as: router.asPath,
        url: router.pathname
      },
      '',
      `${router.pathname}results?${searchParams.toString()}`
    )
  } else if (query[filterName]) {
    delete query[filterName]
    // Convertimos el objeto de consulta a tipo Record<string, string>
    const queryObj = Object.entries(query).reduce((acc, [key, value]) => {
      acc[key] = Array.isArray(value) ? value.join(',') : value
      return acc
    }, {} as Record<string, string>)

    // Utilizamos 'replaceState' para modificar la URL sin recargar la página
    window.history.replaceState(
      {
        ...window.history.state,
        as: router.asPath,
        url: router.pathname
      },
      '',
      `${router.pathname}results?${new URLSearchParams(
        queryObj
      ).toString()}`
    )
  }
}


const findMatchingOption = (
  options: any[],
  queryValue: string,
  prop1: string,
  prop2: string | null
) => {
  return options.find(option => {
    const matchesProp1 = option[prop1].toLowerCase() === queryValue.toLowerCase()
    const matchesProp2 = prop2 && option[prop2].toLowerCase() === queryValue.toLowerCase()
    return matchesProp1 || matchesProp2
  })
}

/*
  La función queryFilterReader se encarga de leer el valor del filtro en la consulta y buscar las opciones
  correspondientes en el arreglo options. Si encuentra coincidencias, construye un nuevo arreglo con las opciones
  encontradas, utilizando las propiedades de comparación y guardando la propiedad especificada en propertyToSave.
  Si no hay coincidencias o el filtro no está presente en la consulta, la función devuelve un arreglo vacío ([]).

  filterName: string: Es el nombre del filtro que se desea leer de la consulta. Es un valor de tipo cadena de texto.
  options: any[]: Es un arreglo de todas las opciones posibles de un filtro
  propertyToCompare1: string = 'name': Es el nombre de la primera propiedad que se utilizará para comparar con el
    filtro en la consulta. Por defecto, el valor es 'name'.
  propertyToCompare2: string | null = 'name_en': Es el nombre de la segunda propiedad opcional que se utilizará
    para comparar con el filtro en la consulta. Puede ser null si no se necesita una segunda propiedad de comparación.
    Por defecto, el valor es 'name_en'.
  propertyToSave: string | null = 'id': Es el nombre de la propiedad que se desea guardar en el resultado. Puede
    ser null si se desea guardar el objeto completo en el state.
*/

export const queryFilterReader = (
  filterName: string,
  options: any[],
  propertyToCompare1: string = 'name',
  propertyToCompare2: string | null = 'name_en',
  propertyToSave: string | null = 'id'
) => {
  const query = getQueryObject()

  if (!query[filterName]) { return [] }

  const queryValues = Array.isArray(query[filterName]) ? query[filterName] : [query[filterName]]
  const matchingOptions = queryValues.reduce((matches, queryValue) => {
    const matchedOption = findMatchingOption(options, queryValue, propertyToCompare1, propertyToCompare2)

    if (matchedOption) {
      const valueToSave = propertyToSave ? matchedOption[propertyToSave] : matchedOption
      matches.push(valueToSave)
    }

    return matches
  }, [])

  return matchingOptions
}


export const getQueryObject = () => {
  const queryParam = new URLSearchParams(window.location.search)

  const query: any = {}

  for (const key of Array.from(queryParam.keys())) {
    query[key] = queryParam.getAll(key)
  }
  return query
}

export const getNews = (language, id) => {
  const news = newsList.find((n) => n.id === id)
  return news[`text_${language}`]
}
